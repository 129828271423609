@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.App {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  max-width: 100vw;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: black;
}

p {
  color: #009914;
}

body {
  margin: 0 auto;
  background-color: black;
}

* {
  box-sizing: border-box;
}
